import React, { createContext } from 'react';

import useAuth from '../hooks/useAuth';
import useHandleResources from '../hooks/useHandleResources';

const Context = createContext();

function AuthProvider({ children }) {
  const { loading, authenticated, handleLogin, handleLogout, user } = useAuth();
  const { rangePeriod, handleRangePeriod, handleAptoUnit, aptoUnit } =
    useHandleResources();
  return (
    <Context.Provider
      value={{
        loading,
        authenticated,
        handleLogin,
        handleLogout,
        user,
        rangePeriod,
        handleRangePeriod,
        handleAptoUnit,
        aptoUnit,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, AuthProvider };
