import { Grid, Toolbar } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import Controls from '../../components/controls/Controls';

export const FilterForm = ({ currentPeriod, handleSearch }) => {
  const conditionItems = [
    { id: 'valorBruto', title: 'Bruto' },
    { id: 'resultadoLiquido', title: 'Resultado Líquido' },
    { id: 'alugado', title: 'Alugado' },
  ];

  const contractType = [
    { id: 'hibrid', title: 'Híbrido' },
    { id: 'rent', title: 'Aluguel' },
    { id: 'partner', title: 'Parceria' },
  ];

  const initial = {
    period: currentPeriod,
    condition: '',
    contract: '',
  };

  async function onSubmit(values, { resetForm }) {
    handleSearch(values);
  }

  return (
    <Toolbar>
      <Formik onSubmit={onSubmit} initialValues={initial}>
        {({ handleSubmit, handleChange, values, setFieldValue }) => (
          <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} lg={4} md={12}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Controls.DatePicker
                    name="period"
                    label="Periodo"
                    value={values.period}
                    onChange={value => setFieldValue('period', value, true)}
                    views={['month']}
                    monthyearformat={1}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Controls.Select
                  name="condition"
                  label="Condicao"
                  value={values.condition}
                  onChange={handleChange}
                  options={conditionItems}
                />
              </Grid>
              <Grid item xs={3}>
                <Controls.Select
                  name="contract"
                  label="Contrato"
                  value={values.contract}
                  onChange={handleChange}
                  options={contractType}
                />
              </Grid>
              <Grid item xs={12} lg={2} md={2} id="periodo">
                <Controls.Button text="Buscar" type="submit" />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Toolbar>
  );
};
