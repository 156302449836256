import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App/App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://dbc031e5a6fa454d887cf3df6dfdd8ef@o735821.ingest.sentry.io/5917026',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
