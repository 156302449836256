import axios from 'axios';

const URL_BACKEND = process.env.REACT_APP_BASE_URL;

export const callToBackend = async (
  endpoint,
  method,
  payload = null,
  params = null
) => {
  const options = {
    url: `${URL_BACKEND}${endpoint}`,
    method,
    params,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    data: payload,
  };

  try {
    const response = await axios(options);
    return { success: true, data: response.data };
  } catch (error) {
    if (error?.response?.status === 401) {
      axios.defaults.headers.Authorization = undefined;
      localStorage.removeItem('@authApp: user');
      localStorage.removeItem('@authApp: token');
      window.location = '/login';
    }

    return { success: false, message: error };
  }
};
