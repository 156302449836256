import React from 'react';

import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    textAlign: 'center',
    boxShadow: 'none',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  indicatorText: {
    fontWeight: 'bold',
  },
});

function NotFounded({ title, icon }) {
  const classes = useStyles();
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item>
        <Card className={classes.root}>
          <CardContent>
            {icon}
            <Typography className={classes.title} gutterBottom>
              {title}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default NotFounded;
