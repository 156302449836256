import { CssBaseline } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from '../styles/main';
import AppBarHy from './AppBarHy';
import DrawerHy from './DrawerHy';

export default function Layout({ children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarHy open={open} handleDrawerOpen={handleDrawerOpen} />
      <DrawerHy open={open} handleDrawerClose={handleDrawerClose} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}
