import React from 'react';
import { ThemeProvider, useTheme } from '@material-ui/core';
import { AuthProvider } from '../Context/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import Routes from '../Routes';

function App() {
  const theme = useTheme();

  return (
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
