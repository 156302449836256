import { Chip, Grid, Paper, Toolbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import PageHeader from '../../components/PageHeader';
import {
  deleteOwner,
  getAllOwners,
  insertOwner,
  updateOwner,
} from '../../services/ownerService';
import Popup from '../../components/Popup';
import Notification from '../../components/Notification';
import ConfirmDialog from '../../components/ConfirmDialog';
import OwnerForm from './OwnerForm';
import Controls from '../../components/controls/Controls';
import { Delete, Edit } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import OwnerImg from '../../assets/images/007-leader.png';

function OwnersPage() {
  const [owners, setOwners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [filteredSearch, setFilteredSearch] = useState('');

  const initialLoad = async () => {
    try {
      setLoading(true);
      const result = await getAllOwners();
      setOwners(result.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    initialLoad();
  }, []);

  const columns = [
    {
      selector: (row) => row.fullName,
      name: 'Nome',
      flex: 1,
    },
    {
      name: 'Apartamentos',
      flex: 2,
      cell: (row) => (
        <Grid container direction="row" spacing={1}>
          {row.announcements.map((ap, index) => (
            <Grid item>
              <Chip size="small" label={ap.internalName} key={index} />
            </Grid>
          ))}
        </Grid>
      ),
    },
    {
      selector: (row) => row.phoneNumber,
      name: 'Telefone',
      flex: 1,
    },
    {
      selector: (row) => row.email,
      name: 'Email',
      flex: 1,
    },
    {
      name: 'Ações',
      // omit: user.role === 'GUEST' || user.role === 'OWNER',
      sortable: false,
      cell: (row) => {
        return (
          <>
            <Controls.ActionButton
              color="primary"
              onClick={() => {
                openInPopup(row);
              }}
            >
              <Edit />
            </Controls.ActionButton>
            <Controls.ActionButton
              color="secondary"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: 'Você tem certeza que quer deletar esse registro?',
                  subTitle: 'Você não pode desfazer essa alteração',
                  onConfirm: () => {
                    onDelete(row._id);
                  },
                });
              }}
            >
              <Delete />
            </Controls.ActionButton>
          </>
        );
      },
    },
  ];

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteOwner(id);
    setOwners(owners.filter((item) => item._id !== id));
    setNotify({
      isOpen: true,
      message: 'Deletado com sucesso!',
      type: 'error',
    });
  };

  const addOrEdit = async (owner, resetForm) => {
    const payload = {
      ...owner,
      announcements: owner.announcements.map((t) => t.value),
    };
    if (payload._id) {
      console.log(payload);
      await updateOwner(payload._id, payload);
      setNotify({
        isOpen: true,
        message: 'Atualizado com sucesso!',
        type: 'success',
      });
    } else {
      await insertOwner(payload);
      setNotify({
        isOpen: true,
        message: 'Inserido com sucesso!',
        type: 'success',
      });
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    initialLoad();
  };

  const ownerFiltered = owners.filter(
    (o) =>
      o.fullName.toLowerCase().includes(filteredSearch) || filteredSearch === ''
  );
  return (
    <>
      <PageHeader
        title="Proprietários"
        subTitle="Lista de proprietários"
        icon={<img alt="iconMenu" src={OwnerImg} height={'35px'} />}
      />
      <Paper>
        <Toolbar>
          <Grid container>
            <Grid item xs>
              <Controls.Button
                size="medium"
                onClick={() => {
                  setOpenPopup(true);
                  setRecordForEdit(null);
                }}
                startIcon={<AddIcon />}
                variant="outlined"
                text="Inserir"
              />
            </Grid>

            <Grid item xs>
              <Controls.Input
                placeholder="Buscar proprietário"
                name="owners"
                value={filteredSearch}
                onChange={(e) => {
                  setFilteredSearch(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <DataTable
          columns={columns}
          data={ownerFiltered}
          pagination
          progressPending={loading}
        />
      </Paper>
      <Popup
        title="Proprietário"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <OwnerForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default OwnersPage;
