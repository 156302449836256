import { addMonths, format } from 'date-fns';

export const mappingStatus = status => {
  const mapping = {
    paid: 'Pago',
    unpaid: 'Não pago',
    waiting: 'Aguardando',
  };
  return mapping[status];
};

export const statusItems = [
  { id: 'paid', title: 'Pago' },
  { id: 'unpaid', title: 'À pagar' },
  { id: 'waiting', title: 'No aguardo' },
];

export const expenseTypes = [
  { id: 'Despesa', title: 'Despesa' },
  { id: 'Crédito', title: 'Crédito' },
  { id: 'Reembolso', title: 'Reembolso' },
];

export const checkAndParseExpense = expense => {
  if (expense.recurring) {
    return _saveRecurringExpense(expense);
  }
  return expense;
};

const _saveRecurringExpense = expense => {
  let listOfExpenses = [];
  for (let i = 0; i < expense.numberOfRecurring; i++) {
    const addMonth = addMonths(expense.launchDate, i);
    const { numberOfRecurring, ...rest } = {
      ...expense,
      launchDate: format(addMonth, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    };
    listOfExpenses.push(rest);
  }
  return listOfExpenses;
};
