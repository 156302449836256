import { Chip, Grid, Paper, Toolbar } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Controls from '../../components/controls/Controls';
import PageHeader from '../../components/PageHeader';
import Popup from '../../components/Popup';
import ExpenseForm from './ExpenseForm';
import AddIcon from '@material-ui/icons/Add';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import {
  deleteExpense,
  getAllExpenses,
  insertExpense,
  updateExpense,
} from '../../services/expenseService';
import { formatToDate, toMonetaryValue } from '../../utils/converter/formatter';
import { LibraryBooks, Search } from '@material-ui/icons';
import Notification from '../../components/Notification';
import ConfirmDialog from '../../components/ConfirmDialog';
import DataTable from 'react-data-table-component';
import { checkAndParseExpense, mappingStatus } from './helpers';
import Select from 'react-select';
import {
  filteredDuplicatesLabel,
  mapToSelectFieldToExpenses,
} from '../../utils/converter/mapToSelect';
import { endOfDay, startOfDay } from 'date-fns';
import LoadingProgress from '../../components/molecules/LoadingProgress';
import NotFounded from '../../components/molecules/NotFounded';
import ExpensesImg from '../../assets/images/003-budget.png';
import { Context } from '../../Context/AuthContext';

export default function Expenses() {
  const { rangePeriod, handleRangePeriod, handleAptoUnit, aptoUnit } =
    useContext(Context);
  const [openedRange, setOpenedRange] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const [announcentsOptions, setAnnouncentsOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const columns = [
    {
      selector: row => row.apto?.internalName || 'Compartilhada',
      grow: 1,
      name: 'Unidade',
      sortable: true,
      filterable: true,
    },
    {
      selector: row => row.description,
      flex: 1,
      name: 'Descrição',
    },
    {
      selector: row => toMonetaryValue(row.expenseValue, true),
      flex: 1,
      name: 'Valor',
      sortable: true,
    },
    {
      selector: row => formatToDate(row.launchDate),
      flex: 1,
      name: 'Lançamento',
      sortable: true,
    },
    {
      selector: row => row.expenseType,
      flex: 1,
      name: 'Tipo',
      sortable: true,
    },
    {
      name: 'Categoria',
      selector: row => row.expenseModel.modelName,
      sortable: true,
      cell: row => (
        <Chip
          avatar={<Avatar>{row.expenseModel.modelName.charAt(0)}</Avatar>}
          label={row.expenseModel.modelName}
        />
      ),
    },
    {
      flex: 1,
      name: 'Status',
      sortable: true,
      cell: row => (
        <Chip
          variant="outlined"
          color="secondary"
          label={mappingStatus(row.status)}
        />
      ),
    },
    {
      name: 'Ações',
      sortable: false,
      cell: row => {
        return (
          <>
            <Controls.ActionButton
              color="primary"
              onClick={() => {
                openInPopup(row);
              }}
            >
              <Edit />
            </Controls.ActionButton>
            <Controls.ActionButton
              color="secondary"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: 'Você tem certeza que quer deletar esse registro?',
                  subTitle: 'Você não pode desfazer essa alteração',
                  onConfirm: () => {
                    onDelete(row._id);
                  },
                });
              }}
            >
              <Delete />
            </Controls.ActionButton>
          </>
        );
      },
    },
  ];

  const openInPopup = item => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const consultExpenses = async (start = '', end = '') => {
    try {
      setLoading(true);
      const { success, data } = await getAllExpenses(
        startOfDay(start),
        endOfDay(end)
      );
      if (success) {
        setExpenses(data);
        setAnnouncentsOptions(
          filteredDuplicatesLabel(mapToSelectFieldToExpenses(data))
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    consultExpenses(rangePeriod.startDate, rangePeriod.endDate);
  }, [rangePeriod]);

  const addOrEdit = async (expense, resetForm) => {
    const payload = {
      ...expense,
      apto: expense.apto['value'],
    };
    if (payload.shared) {
      delete payload.apto;
    }
    if (payload._id) {
      await updateExpense(payload._id, payload);
      setNotify({
        isOpen: true,
        message: 'Atualizada com sucesso!',
        type: 'success',
      });
    } else {
      await insertExpense(checkAndParseExpense(payload));
      setNotify({
        isOpen: true,
        message: 'Inserida com sucesso!',
        type: 'success',
      });
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    consultExpenses(rangePeriod.startDate, rangePeriod.endDate);
  };

  const announcementFilter = expenses.filter(announcement => {
    if (announcement.shared && selectedField === 'Compartilhada') {
      return announcement;
    }
    return (
      announcement.apto !== undefined &&
      announcement.apto?.internalName?.includes(selectedField)
    );
  });

  const periodButtonText =
    (rangePeriod.formattedStartDate && rangePeriod.formattedEndDate) !==
    undefined
      ? `${rangePeriod.formattedStartDate} à ${rangePeriod.formattedEndDate}`
      : 'a definir';

  const onDelete = id => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteExpense(id);
    setExpenses(expenses.filter(item => item._id !== id));
    setNotify({
      isOpen: true,
      message: 'Deletado com sucesso!',
      type: 'error',
    });
  };

  const onChangeDate = _range => {
    if (
      formatToDate(_range.startDate) !== formatToDate(_range.endDate) ||
      (_range.startDate === '' && _range.endDate === '')
    ) {
      setOpenedRange(false);
    }
  };

  const handleOnChange = ranges => {
    const { selection } = ranges;
    handleRangePeriod(ranges);
    onChangeDate(selection);
  };

  const selectValue = aptoUnit.label
    ? aptoUnit
    : { label: 'Filtrar unidade', value: '' };

  return (
    <>
      <PageHeader
        title="Despesas e Receitas (extras)"
        subTitle="Itens de despesas, reembolsos e receitas (extras)"
        icon={<img alt="iconMenu" src={ExpensesImg} height={'35px'} />}
      />
      <Paper>
        <Toolbar>
          <Grid container>
            <Grid item xs={8}>
              <Controls.Button
                size="medium"
                onClick={() => {
                  setOpenPopup(true);
                  setRecordForEdit(null);
                }}
                startIcon={<AddIcon />}
                variant="outlined"
                text="Inserir"
              />
              <Controls.Button
                type="submit"
                size="medium"
                variant="outlined"
                startIcon={<DateRangeIcon />}
                text={`Período - ${periodButtonText}`}
                onClick={() => setOpenedRange(!openedRange)}
              />
              {openedRange && (
                <Controls.DateRangeCustom
                  onChange={handleOnChange}
                  range={[rangePeriod]}
                />
              )}
              <Controls.Button
                size="medium"
                onClick={() => {
                  consultExpenses(rangePeriod.startDate, rangePeriod.endDate);
                }}
                startIcon={<Search />}
                text="Buscar"
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                name="announcements"
                defaultValue={{
                  ...selectValue,
                }}
                onChange={e => {
                  setSelectedField(e.label);
                  handleAptoUnit(e);
                }}
                options={announcentsOptions}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <DataTable
          columns={columns}
          data={announcementFilter}
          pagination
          striped
          dense
          highlightOnHover
          progressPending={loading}
          noDataComponent={
            <NotFounded
              title="Não foram encontrados registros com os critérios de busca
              especificados."
              icon={<LibraryBooks fontSize="large" />}
            />
          }
          progressComponent={<LoadingProgress />}
        />
      </Paper>

      <Popup
        title="Despesas e Receitas"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ExpenseForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
