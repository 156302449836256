import React, { useContext, useState } from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  Collapse,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemText,
  useTheme,
} from '@material-ui/core';
import { useStyles } from '../styles/main';
import { List } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import DashboardImg from '../assets/images/001-business-report.png';
import ReportImg from '../assets/images/010-business-report.png';
import BookingsImg from '../assets/images/002-booking.png';
import ExpensesImg from '../assets/images/003-budget.png';
import BuildingImg from '../assets/images/004-office-building.png';
import RoomImg from '../assets/images/006-living-room.png';
import OwnerImg from '../assets/images/007-leader.png';
import ExpenseModelImg from '../assets/images/008-income.png';
import SharedImg from '../assets/images/009-expenses.png';
import RegisterImg from '../assets/images/011-registered.png';
import ReportList from '../assets/images/012-report-list.png';
import ReportGenerate from '../assets/images/013-report-generate.png';

import { Context } from '../Context/AuthContext';

export default function DrawerHy({ open, handleDrawerClose }) {
  const { user } = useContext(Context);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const [registerOpen, setRegisterOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);

  const isAdminOrManager = user.role === 'ADMIN' || user.role === 'MANAGER';

  const menuItens = [
    {
      text: 'Painel de Controle',
      icon: DashboardImg,
      path: '/',
    },    
    {
      text: 'Reservas',
      icon: BookingsImg,
      path: '/reservas',
    },
    isAdminOrManager && {
      text: 'Despesas',
      icon: ExpensesImg,
      path: '/despesas',
    },
  ];

  const relatorioItens = [
    {
      text: 'Gerar Relatório',
      icon: ReportGenerate,
      path: '/report',
    },
    {
      text: 'Lista de Relatórios',
      icon: ReportList,
      path: '/report-list',
    },
  ]

  const cadastroItens = [
    {
      text: 'Prédios',
      icon: BuildingImg,
      path: '/predios',
    },
    {
      text: 'Apartamentos',
      icon: RoomImg,
      path: '/apartamentos',
    },
    {
      text: 'Proprietários',
      icon: OwnerImg,
      path: '/proprietarios',
    },
    {
      text: 'Modelos de Despesas',
      icon: ExpenseModelImg,
      path: '/modelo-despesa',
    },
    {
      text: 'Divisão do Mês',
      icon: SharedImg,
      path: '/divisao',
    },
  ];  

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar}>
        <img
          className={classes.appBarImgLogo}
          alt="hylogo"
          src="https://www.reservas.hy4u.com.br/media/6141fb6729065eca692ad4d2/sm"
          height={'30px'}
        />
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        {menuItens.map((item) => (
          <ListItem
            button
            key={item.text}
            onClick={() => history.push(item.path)}
            className={location.pathname === item.path ? classes.active : null}
          >
            <img
              alt="iconMenu"
              src={item.icon}
              height={'25px'}
              className={classes.spacingMenuIcon}
            />
            <ListItemText primary={item.text} />
          </ListItem>
        ))}        
          <>
            <Divider />
            <ListItem button onClick={()=>setReportOpen(!reportOpen)}>
              <img
                alt="iconMenu"
                src={ReportImg}
                height={'25px'}
                className={classes.spacingMenuIcon}
              />
              <ListItemText primary="Relatório" />
              {reportOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={reportOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {relatorioItens.map((item) => (
                  <ListItem
                    button
                    key={item.text}
                    onClick={() => history.push(item.path)}
                    className={
                      location.pathname === item.path ? classes.active : null
                    }
                  >
                    <img
                      alt="iconMenu"
                      src={item.icon}
                      height={'25px'}
                      className={classes.spacingMenuIcon}
                    />
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </>        
        {isAdminOrManager && (
          <>
            <Divider />
            <ListItem button onClick={()=>setRegisterOpen(!registerOpen)}>
              <img
                alt="iconMenu"
                src={RegisterImg}
                height={'25px'}
                className={classes.spacingMenuIcon}
              />
              <ListItemText primary="Cadastros" />
              {registerOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={registerOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {cadastroItens.map((item) => (
                  <ListItem
                    button
                    key={item.text}
                    onClick={() => history.push(item.path)}
                    className={
                      location.pathname === item.path ? classes.active : null
                    }
                  >
                    <img
                      alt="iconMenu"
                      src={item.icon}
                      height={'25px'}
                      className={classes.spacingMenuIcon}
                    />
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </>
        )}
      </List>
    </Drawer>
  );
}
