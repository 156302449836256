import { callToBackend } from '../utils/api/genericCallToApi';

export const getAllAnnouncements = async () => {
  const user = localStorage.getItem('@authApp: user');
  const hasAnnouncements = checkAdminOrManagerUser(user);

  return hasAnnouncements.length
    ? { success: true, data: hasAnnouncements }
    : await callToBackend('announcement', 'GET');
};

export const insertAnnouncement = async announcement => {
  const payload = JSON.stringify(announcement);
  await callToBackend('announcement', 'POST', payload);
};

export const updateAnnouncement = async (id, announcement) => {
  const payload = JSON.stringify(announcement);
  await callToBackend(`announcement/${id}`, 'PATCH', payload);
};

const checkAdminOrManagerUser = user => {
  const parsedUser = JSON.parse(user);
  const isAdminOrManager =
    parsedUser.role === 'ADMIN' || parsedUser.role === 'MANAGER';

  return parsedUser && !isAdminOrManager
    ? parsedUser?.associatedOwner?.announcements
    : [];
};
