import { Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import * as Yup from 'yup';
import Controls from '../../components/controls/Controls';
import { getAllBuildings } from '../../services/buildingService';
import { getAllExpensesModel } from '../../services/expenseService';
import { replaceBuildingName } from '../../utils/converter/formatter';
import {
  mapToSelectField,
  mapToSelectField2,
} from '../../utils/converter/mapToSelect';
import { expenseTypes, statusItems } from './helpers';

const schema = Yup.object().shape({
  launchDate: Yup.date().required('Campo obrigatório'),
  building: Yup.object().when('shared', {
    is: false,
    then: Yup.object().shape({
      label: Yup.string().required('Campo obrigatório'),
      value: Yup.string().required('Campo obrigatório'),
    }),
  }),
  apto: Yup.object().when('shared', {
    is: false,
    then: Yup.object().shape({
      label: Yup.string().required('Campo obrigatório'),
      value: Yup.string().required('Campo obrigatório'),
    }),
  }),
  description: Yup.string().required('Campo obrigatório'),
  expenseModel: Yup.string().required('Campo obrigatório'),
  expenseValue: Yup.string().required('Campo obrigatório'),
  shared: Yup.boolean().required('Campo obrigatório'),
  status: Yup.string().required('Campo obrigatório'),
  numberOfRecurring: Yup.number().when('recurring', {
    is: true,
    then: Yup.number().min(1, 'É necessário mais de 1 recorrência'),
  }),
});

export default function ExpenseForm({ addOrEdit, recordForEdit }) {
  const initial = {
    launchDate: new Date(),
    building: { label: 'Selecione', value: '' },
    apto: { label: 'Selecione', value: '' },
    description: '',
    expenseModel: '',
    expenseType: '',
    expenseValue: '',
    shared: false,
    recurring: false,
    numberOfRecurring: 0,
    status: 'waiting',
  };
  const [initialValues, setInitalValues] = useState(initial);
  const [submitButtonText, setSubmitButtonText] = useState('Inserir');
  const [shared, setShared] = useState(null);
  const [recurring, setRecurring] = useState(null);
  const [expenseModel, setExpenseModel] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [buildingsAnnoucements, setBuildingsAnnoucements] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  async function onSubmit(values, { resetForm }) {
    addOrEdit(values, resetForm);
  }

  const initialLoad = async () => {
    const [expenseModel, building] = await Promise.all([
      getAllExpensesModel(),
      getAllBuildings(),
    ]);
    if (expenseModel.success && building.success) {
      setExpenseModel(mapToSelectField(expenseModel.data, 'modelName'));
      setBuildings(
        mapToSelectField2(building.data, 'name').map(b => ({
          ...b,
          label: replaceBuildingName(b.label),
        }))
      );
      setBuildingsAnnoucements(building.data);
    }
  };

  const announcementsFromBuilding = React.useCallback(
    id => {
      const result = buildingsAnnoucements.find(a => a._id === id);
      return result?.announcements;
    },
    [buildingsAnnoucements]
  );

  useEffect(() => {
    initialLoad();
  }, []);

  const fixBuildingFormat = building => ({
    label: building?.name,
    value: building?._id,
  });

  const fixAnnouncementFormat = announcement => ({
    label: announcement?.internalName,
    value: announcement?._id,
  });

  useEffect(() => {
    if (recordForEdit != null) {
      setSubmitButtonText('Atualizar');
      setShared(recordForEdit.shared);
      setRecurring(recordForEdit.recurring);
      if (buildingsAnnoucements.length > 1 && !recordForEdit.shared) {
        setAnnouncements(
          mapToSelectField2(
            announcementsFromBuilding(
              String(recordForEdit?.apto?.building?._id)
            ),
            'internalName'
          )
        );
      }
      setInitalValues(() => ({
        ...recordForEdit,
        building: fixBuildingFormat(recordForEdit?.apto?.building) || {
          label: '',
          value: '',
        },

        apto: fixAnnouncementFormat(recordForEdit?.apto) || {
          label: '',
          value: '',
        },

        expenseModel: String(recordForEdit.expenseModel._id),
      }));
    }
  }, [
    recordForEdit,
    buildingsAnnoucements,
    announcementsFromBuilding,
    submitButtonText,
  ]);

  return (
    <Formik
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue,
        isValid,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={recurring ? 3 : 4}>
              <Controls.Select
                name="expenseType"
                label="Tipo"
                value={values.expenseType}
                onChange={handleChange}
                options={expenseTypes}
                error={errors.expenseType}
              />
            </Grid>
            <Grid item xs={recurring ? 3 : 4}>
              <Controls.Select
                name="expenseModel"
                label="Categoria"
                value={values.expenseModel}
                onChange={handleChange}
                options={expenseModel}
                error={errors.expenseModel && touched.expenseModel}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Checkbox
                name="shared"
                label="Compartilhada"
                value={values.shared}
                onChange={handleChange}
                onClick={() => setShared(!shared)}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Checkbox
                name="recurring"
                label="Recorrente"
                value={values.recurring}
                onChange={handleChange}
                onClick={() => setRecurring(!recurring)}
              />
            </Grid>
            {recurring && (
              <Grid item xs={2}>
                <Controls.Input
                  name="numberOfRecurring"
                  label="Recorrência"
                  type="number"
                  value={values.numberOfRecurring}
                  onChange={handleChange}
                  error={errors.numberOfRecurring && touched.numberOfRecurring}
                />
              </Grid>
            )}
            {!shared && (
              <>
                <Grid item xs={3}>
                  <Select
                    name="building"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                    value={values.building}
                    onChange={value => {
                      setFieldValue('building', value);
                      setAnnouncements(
                        mapToSelectField2(
                          announcementsFromBuilding(value.value),
                          'internalName'
                        )
                      );
                    }}
                    placeholder={'Prédios'}
                    options={buildings}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    className="custom-select"
                    name="apto"
                    options={announcements}
                    component={Controls.SelectCustom}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <Controls.Input
                name="description"
                label="Descrição"
                value={values.description}
                onChange={handleChange}
                error={errors.description && touched.description}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.DatePicker
                name="launchDate"
                label="Data de cadastro"
                value={values.launchDate}
                onChange={value => setFieldValue('launchDate', value, true)}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Input
                name="expenseValue"
                InputProps={{
                  inputComponent: Controls.NumberFormatCustom,
                }}
                label="Valor"
                value={values.expenseValue}
                onChange={handleChange}
                error={errors.expenseValue}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.RadioGroup
                name="status"
                label="Status"
                value={values.status}
                onChange={handleChange}
                items={statusItems}
              />
            </Grid>
          </Grid>
          <div>
            <Controls.Button
              type="submit"
              disabled={!isValid}
              text={submitButtonText}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
