import { Avatar, Chip, Grid, Paper, Toolbar } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import PageHeader from '../../components/PageHeader';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import {
  getAllAnnouncements,
  insertAnnouncement,
  updateAnnouncement,
} from '../../services/announcementService';
import { replaceBuildingName } from '../../utils/converter/formatter';
import LoadingProgress from '../../components/molecules/LoadingProgress';
import NotFounded from '../../components/molecules/NotFounded';
import Controls from '../../components/controls/Controls';
import Popup from '../../components/Popup';
import AnnouncementForm from './AnnouncementForm';
import AnnouncementInsertForm from './AnnouncementInsertForm';
import Notification from '../../components/Notification';
import ConfirmDialog from '../../components/ConfirmDialog';
import Edit from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import RoomImg from '../../assets/images/006-living-room.png';
import { Context } from '../../Context/AuthContext';
import { mappingStatus } from './helpers';

function AnnouncementsPage() {
  const { user } = useContext(Context);
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupInsert, setOpenPopupInsert] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [filteredSearch, setFilteredSearch] = useState('');

  const initialLoad = async () => {
    try {
      setLoading(true);
      const result = await getAllAnnouncements();
      setAnnouncements(result.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    initialLoad();
  }, []);

  const openInPopup = item => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const columns = [
    {
      selector: row => row.internalName,
      name: 'Nome interno',
    },
    {
      selector: row => row?.owner?.fullName || '',
      name: 'Proprietário',
      sortable: true,
    },
    {
      selector: row => replaceBuildingName(row.building?.name) || '',
      name: 'Prédio',
      sortable: true,
    },
    {
      selector: row => row.staysId,
      name: 'Id Stays',
      minWidth: '70px',
      maxWidth: '90px',
    },
    {
      name: 'Status',
      minWidth: '70px',
      maxWidth: '150px',
      cell: row => (
        <Chip
          variant="outlined"
          color="primary"
          label={mappingStatus(row.status)}
        />
      ),
    },
    {
      selector: row => calcCondition(row),
      name: 'Modelo de Cálculo',
    },
    {
      name: 'Modelo de Contrato',
      selector: row => row.contract?.type || '',
      cell: row => (
        <Chip
          avatar={
            <Avatar>{contractType[row.contract?.type || ''].charAt(0)}</Avatar>
          }
          label={contractType[row.contract?.type || '']}
        />
      ),
    },
    {
      name: 'Ações',
      omit: user.role === 'GUEST' || user.role === 'OWNER',
      sortable: false,
      cell: row => {
        return (
          <>
            <Controls.ActionButton
              color="primary"
              onClick={() => {
                openInPopup(row);
              }}
            >
              <Edit />
            </Controls.ActionButton>
          </>
        );
      },
    },
  ];

  const addOrEdit = async (expense, resetForm) => {
    const payload = {
      ...expense,
    };
    if (payload._id) {
      await updateAnnouncement(payload._id, payload);
      setNotify({
        isOpen: true,
        message: 'Atualizada com sucesso!',
        type: 'success',
      });
      setOpenPopup(false);
    } else {
      await insertAnnouncement(payload);
      setNotify({
        isOpen: true,
        message: 'Inserido com sucesso!',
        type: 'success',
      });
      setOpenPopupInsert(false);
    }
    resetForm();
    setRecordForEdit(null);

    initialLoad();
  };

  const contractType = {
    hibrid: 'Híbrido',
    rent: 'Aluguel',
    partner: 'Parceria',
    '': 'A definir',
  };

  const calcCondition = row => {
    const conditionMap = {
      resultadoLiquido: 'Resultado Líquido',
      valorBruto: 'Valor Bruto',
      alugado: 'Alugado',
      '': 'A definir',
    };
    const conditionValue = row.billing?.value || 0;
    const conditionDescription = row.billing?.condition || '';

    return `${conditionValue}% - ${conditionMap[conditionDescription]}`;
  };

  const announcementFiltered = announcements?.filter(
    o =>
      o.internalName?.toLowerCase().includes(filteredSearch) ||
      o.owner?.fullName?.toLowerCase().includes(filteredSearch) ||
      filteredSearch === ''
  );

  return (
    <>
      <PageHeader
        title="Apartamentos"
        subTitle="Lista de apartamentos"
        icon={<img alt="iconMenu" src={RoomImg} height={'35px'} />}
      />
      <Paper>
        <Toolbar>
          <Grid container>
            <Grid item xs>
              <Controls.Button
                size="medium"
                onClick={() => {
                  setOpenPopupInsert(true);
                  setRecordForEdit(null);
                }}
                startIcon={<AddIcon />}
                variant="outlined"
                text="Inserir"
              />
            </Grid>
            <Grid item xs={4}>
              <Controls.Input
                placeholder="Buscar por apartamento ou proprietário"
                name="announcement"
                value={filteredSearch}
                onChange={e => {
                  setFilteredSearch(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <DataTable
          columns={columns}
          data={announcementFiltered}
          striped
          highlightOnHover
          pagination
          progressPending={loading}
          progressComponent={<LoadingProgress />}
          noDataComponent={
            <NotFounded
              title="Não foram encontrados registros com os critérios de busca
              especificados."
              icon={<BusinessRoundedIcon fontSize="large" />}
            />
          }
        />
      </Paper>
      <Popup title="Anúncios" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <AnnouncementForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Popup
        title="Inserir Anúncio"
        openPopup={openPopupInsert}
        setOpenPopup={setOpenPopupInsert}
      >
        <AnnouncementInsertForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default AnnouncementsPage;
