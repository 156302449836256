import { callToBackend } from '../utils/api/genericCallToApi';

export const generateReport = async (startDate, endDate, announcementId) => {
  return await callToBackend('report/generate', 'GET', null, {
    startDate,
    endDate,
    announcementId,
  });
};

export const updateReport = async (id, report) => {
  const payload = JSON.stringify(report);
  return await callToBackend(`report/${id}`, 'PATCH', payload);
};

export const deleteReport = async id => {
  await callToBackend(`report/${id}`, 'DELETE');
};

export const consulAlltReports = async ({ period, contract, condition }) => {
  return await callToBackend('report', 'GET', null, {
    reportPeriod: period,
    contract,
    condition,
  });
};
