import { callToBackend } from '../utils/api/genericCallToApi';

export const getAllOwners = async () => {
  return await callToBackend('owner', 'GET');
};
export const insertOwner = async (owner) => {
  const payload = JSON.stringify(owner);
  await callToBackend('owner', 'POST', payload);
};

export const updateOwner = async (id, owner) => {
  const payload = JSON.stringify(owner);
  await callToBackend(`owner/${id}`, 'PATCH', payload);
};

export const deleteOwner = async (id) => {
  await callToBackend(`owner/${id}`, 'DELETE');
};
