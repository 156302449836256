import { Grid, TextField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Controls from '../../components/controls/Controls';

export default function ReportForm({ addOrEdit, recordForEdit }) {
  const initial = {
    comments: '',
  };
  const [initialValues, setInitalValues] = useState(initial);

  async function onSubmit(values, { resetForm }) {
    addOrEdit(values, resetForm);
  }

  useEffect(() => {
    if (recordForEdit != null) {
      setInitalValues(() => ({
        ...recordForEdit,
      }));
    }
  }, [recordForEdit]);

  return (
    <Formik      
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        values,
        isSubmitting,
      }) => (
        <div style={{width:600}}>

        <Form noValidate onSubmit={handleSubmit}>
          <Grid container >
            <Grid item style={{width:'100%'}}>            
              <TextField
                variant="outlined"
                label="Observação"
                name="comments"
                fullWidth
                multiline
                rows={5}
                onChange={handleChange}
                value={values?.comments}             
              />
            </Grid>            
          </Grid>
          <div>
            <Controls.Button
              type="submit"
              disabled={isSubmitting}
              text={'Atualizar'}
            />
          </div>
        </Form>
        </div>
      )}
    </Formik>
  );
}
