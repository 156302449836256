import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { toMonetaryValue } from '../../utils/converter/formatter';

const useStyles = makeStyles({
  root: {
    minWidth: 205,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0 0 11px rgba(33,33,33,.2)',
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  indicatorText: {
    fontWeight: 'bold',
  },
});

function InfoCard({ bgColor, fontColor, title, value, monetary }) {
  const classes = useStyles();
  return (
    <Grid item xs>
      <Card
        className={classes.root}
        variant="outlined"
        style={{
          backgroundColor: bgColor,
        }}
      >
        <CardContent>
          <Typography
            className={classes.title}
            style={{ color: fontColor }}
            gutterBottom
          >
            {title}
          </Typography>

          <Typography variant="h5" component="h2" style={{ color: fontColor }}>
            {monetary ? toMonetaryValue(value, true) : value}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default InfoCard;
