export const schemeColors = [
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
];

export const schemeColors2 = [
  '#3B69B7',
  '#d43d51',
  '#2f4b7c',
  '#bdcf75',
  '#e66a4d',
  '#ffa600',
  '#78B28D',
  '#e66a4d',
  '#4fa16e',
];

export const schemeColors3 = [
  '#2D7CE3',
  '#33B890',
  '#ff7c43',
  '#634CC2',
  '#ffa600',
];
