import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import Controls from '../../components/controls/Controls';

export default function SharedForm({ addOrEdit, recordForEdit }) {
  const initial = {
    launchDate: new Date(),
    quantity: 0,
  };
  const [initialValues, setInitalValues] = useState(initial);
  const [submitButtonText, setSubmitButtonText] = useState('Inserir');

  async function onSubmit(values, { resetForm }) {
    // console.log(values, { resetForm });
    addOrEdit(values, resetForm);
  }

  useEffect(() => {
    if (recordForEdit != null) {
      setSubmitButtonText('Atualizar divisão');
      setInitalValues(() => ({
        ...recordForEdit,
      }));
    }
  }, [recordForEdit, submitButtonText]);

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <Controls.DatePicker
                name="launchDate"
                label="Data referência"
                value={values.launchDate}
                onChange={value => setFieldValue('launchDate', value, true)}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="quantity"
                label="Quantidade do mês"
                value={values.quantity}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Controls.Button
                type="submit"
                disabled={isSubmitting}
                text={submitButtonText}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
