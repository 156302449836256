import { Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import ApartmentIcon from '@material-ui/icons/Apartment';
import {
  getAllBuildings,
  insertBuilding,
  updateBuilding,
} from '../../services/buildingService';
import Controls from '../../components/controls/Controls';
import { Edit } from '@material-ui/icons';
import Popup from '../../components/Popup';
import Notification from '../../components/Notification';
import ConfirmDialog from '../../components/ConfirmDialog';
import BuildingForm from './BuildingForm';
import DataTable from 'react-data-table-component';
import LoadingProgress from '../../components/molecules/LoadingProgress';
import NotFounded from '../../components/molecules/NotFounded';
import { replaceBuildingName } from '../../utils/converter/formatter';
import BuildingImg from '../../assets/images/004-office-building.png';

export default function Buildings() {
  const [buildings, setBuildings] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const initialLoad = async () => {
    try {
      setLoading(true);
      const result = await getAllBuildings();
      setBuildings(result.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    initialLoad();
  }, []);

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addOrEdit = async (building, resetForm) => {
    const payload = {
      ...building,
      announcements: building.announcements.map((t) => t.value),
    };
    if (payload._id) {
      await updateBuilding(payload._id, payload);
      setNotify({
        isOpen: true,
        message: 'Atualizado com sucesso!',
        type: 'success',
      });
    } else {
      await insertBuilding(payload);
      setNotify({
        isOpen: true,
        message: 'Inserida com sucesso!',
        type: 'success',
      });
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    initialLoad();
  };

  const columns = [
    {
      selector: (row) => replaceBuildingName(row.name),
      name: 'Nome',
      flex: 1,
      sortable: false,
    },
    {
      selector: (row) => row.address.city,
      name: 'Cidade',
      flex: 1,
    },
    {
      selector: (row) => row.address.district,
      name: 'Bairro',
      flex: 1,
    },
    {
      selector: (row) => row.announcements.length,
      name: 'Anúncios',
      width: 150,
    },
    {
      name: 'Ações',
      sortable: false,
      cell: (row) => {
        return (
          <Controls.ActionButton
            color="primary"
            onClick={() => {
              openInPopup(row);
            }}
          >
            <Edit />
          </Controls.ActionButton>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader
        title="Prédios"
        subTitle="Lista de prédios"
        icon={<img alt="iconMenu" src={BuildingImg} height={'35px'} />}
      />
      <Paper>
        <DataTable
          columns={columns}
          data={buildings}
          striped
          dense
          highlightOnHover
          pagination
          progressPending={loading}
          progressComponent={<LoadingProgress />}
          noDataComponent={
            <NotFounded
              title="Não foram encontrados registros com os critérios de busca
              especificados."
              icon={<ApartmentIcon fontSize="large" />}
            />
          }
        />
      </Paper>
      <Popup title="Prédio" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <BuildingForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
