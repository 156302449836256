import { Grid, IconButton } from '@material-ui/core';
import { FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import * as Yup from 'yup';
import Controls from '../../components/controls/Controls';

// eslint-disable-next-line
const schema = Yup.object().shape({
  announcements: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
  ),
  name: Yup.string().required('Campo obrigatório'),
  description: Yup.string(),
});

const statusItems = [
  { id: 'repassada', title: 'Repassada' },
  { id: 'a repassar', title: 'A Repassar' },
];

export default function BookingsForm({ addOrEdit, recordForEdit }) {
  const initial = {
    Chegada: '',
    Saida: '',
    Canal: '',
    DuracaoIntervalo: 0,
    StatusRepasse: '',
    BaseRepasse: 0,
    PrecoCorrigido: 0,
    EncargosVenda: [],
  };
  const [initialValues, setInitalValues] = useState(initial);
  const [submitButtonText, setSubmitButtonText] = useState('Inserir');
  const [repasseValue, setRepasseValue] = useState(0);
  const [corrigidoValue, setCorrigidoValue] = useState(0);

  async function onSubmit(values, { resetForm }) {
    addOrEdit(
      {
        ...values,
        TotalRepasse: repasseValue.toFixed(2),
        PrecoCorrigido: corrigidoValue.toFixed(2),
      },
      resetForm
    );
  }

  useEffect(() => {
    if (recordForEdit != null) {
      setSubmitButtonText('Atualizar reserva');
      setInitalValues(() => ({
        ...recordForEdit,
      }));
    }
  }, [recordForEdit, submitButtonText]);

  return (
    <Formik
      // validationSchema={schema}
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Controls.DatePicker
                name="Chegada"
                label="CheckIn"
                value={values.Chegada}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.DatePicker
                name="Saida"
                label="CheckOut"
                value={values.Saida}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={3}>
              <Controls.Input
                name="DuracaoIntervalo"
                label="Diárias"
                disabled
                value={values.DuracaoIntervalo}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Input
                name="Canal"
                label="Canal"
                value={values.Canal}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Select
                name="StatusRepasse"
                label="Status"
                value={values.StatusRepasse}
                onChange={handleChange}
                options={statusItems}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Input
                name="BaseRepasse"
                label="Valor Bruto"
                value={values.BaseRepasse}
                disabled
              />
            </Grid>
            {setRepasseValue(
              values?.EncargosVenda.reduce((acc, item) => acc + item.val, 0)
            )}
            <Grid item xs={3}>
              <Controls.Input
                name="TotalRepasse"
                label="Encargos"
                value={repasseValue.toFixed(2)}
                disabled
              />
            </Grid>
            {setCorrigidoValue(values.BaseRepasse - repasseValue)}
            <Grid item xs={3}>
              <Controls.Input
                name="PrecoCorrigido"
                label="Valor Líquido"
                value={corrigidoValue.toFixed(2)}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <fieldset
                style={{
                  paddingTop: '15px',
                  borderColor: '#f1f1f1f1',
                  borderRadius: '5px',
                }}
              >
                <Grid>
                  <FieldArray name="EncargosVenda">
                    {({ push, remove }) => (
                      <>
                        {values.EncargosVenda.map((tax, index) => (
                          <Grid
                            container
                            alignItems="center"
                            spacing={2}
                            key={index}
                          >
                            <Grid item xs={4}>
                              <Controls.Input
                                name={`EncargosVenda.${index}.desc`}
                                value={tax.desc}
                                label="Taxa"
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Controls.Input
                                name={`EncargosVenda.${index}.val`}
                                label={'Valor'}
                                defaultValue={tax.val}
                                onChange={handleChange}
                                type="number"
                                step="0.01"
                              />
                            </Grid>
                            <Grid item>
                              <IconButton
                                disabled={
                                  isSubmitting ||
                                  values.EncargosVenda.length === 1
                                }
                                aria-label="delete"
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                        <Grid item>
                          <Controls.Button
                            variant="outlined"
                            type="button"
                            text={'Adicionar Taxa'}
                            disabled={isSubmitting}
                            onClick={() => push({ desc: '', val: 0 })}
                          />
                        </Grid>
                      </>
                    )}
                  </FieldArray>
                </Grid>
              </fieldset>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Controls.Button
                type="submit"
                disabled={isSubmitting}
                text={submitButtonText}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
