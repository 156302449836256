import { format } from 'date-fns';
import { callToBackend } from '../utils/api/genericCallToApi';

export const getAllBookings = async () => {
  return await callToBackend('bookings', 'GET');
};

export const getBookingsFiltered = async (
  startDate,
  endDate,
  announcementId
) => {
  return await callToBackend('bookings/filter', 'GET', null, {
    startDate,
    endDate,
    announcementId,
  });
};

export const syncBookings = async (start, end, listingId) => {
  return await callToBackend('bookings', 'POST', {
    from: format(start, 'yyyy-MM-dd'),
    to: format(end, 'yyyy-MM-dd'),
    listingId: [listingId],
  });
};
// export const insertBooking = async (booking) => {
//   const payload = JSON.stringify(booking);
//   await callToBackend('bookings', 'POST', payload);
// };

export const updateBooking = async (id, booking) => {
  const payload = JSON.stringify(booking);
  await callToBackend(`bookings/${id}`, 'PATCH', payload);
};

export const deleteBooking = async (id) => {
  await callToBackend(`bookings/${id}`, 'DELETE');
};
