import { Grid, TextField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Controls from '../../components/controls/Controls';
import { replaceBuildingName } from '../../utils/converter/formatter';

const conditionItems = [
  { id: 'valorBruto', title: 'Bruto' },
  { id: 'resultadoLiquido', title: 'Resultado Líquido' },
  { id: 'alugado', title: 'Alugado' },
];

const statusItems = [
  { id: 'active', title: 'Ativo' },
  { id: 'inactive', title: 'Desativado' },
  { id: 'suspended', title: 'Suspenso' },
];

const contractType = [
  { id: 'hibrid', title: 'Híbrido' },
  { id: 'rent', title: 'Aluguel' },
  { id: 'partner', title: 'Parceria' },
];

export default function AnnouncementForm({ addOrEdit, recordForEdit }) {
  const initial = {
    billing: { condition: '', value: 0 },
    contract: { type: '' },
    status: '',
  };
  const [initialValues, setInitalValues] = useState(initial);

  async function onSubmit(values, { resetForm }) {
    addOrEdit(values, resetForm);
  }

  useEffect(() => {
    if (recordForEdit != null) {
      setInitalValues(() => ({
        ...recordForEdit,
      }));
    }
  }, [recordForEdit]);

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                label="Apartamento"
                fullWidth
                name="internalName"
                value={values?.internalName}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                label="Proprietário"
                fullWidth
                value={values?.owner?.fullName}
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                label="Prédio"
                fullWidth
                value={replaceBuildingName(values?.building?.name)}
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                label="Id Stays"
                fullWidth
                name="staysId"
                value={values.staysId}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Select
                name="status"
                label="Status"
                value={values.status}
                onChange={handleChange}
                options={statusItems}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Select
                name="billing.condition"
                label="Condição de Cálculo"
                value={values?.billing?.condition}
                onChange={handleChange}
                options={conditionItems}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                label="Valor da Taxa"
                name="billing.value"
                value={values?.billing?.value}
                fullWidth
                size="small"
                type="number"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Select
                name="contract.type"
                label="Tipo"
                value={values?.contract?.type}
                onChange={handleChange}
                options={contractType}
              />
            </Grid>
          </Grid>
          <div>
            <Controls.Button
              type="submit"
              disabled={isSubmitting}
              text={'Atualizar'}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
