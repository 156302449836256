import React from 'react';
import { DateRange } from 'react-date-range';
import { ptBR } from 'date-fns/locale';

const DateRangeCustom = ({ onChange, range }) => {
  return (
    <div style={{ position: 'absolute', zIndex: 999, top: '50px' }}>
      <DateRange
        editableDateInputs
        showSelectionPreview
        moveRangeOnFirstSelection={false}
        onChange={onChange}
        ranges={range}
        locale={ptBR}
      />
    </div>
  );
};

export default DateRangeCustom;
