import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

export default function DatePicker(props) {
  const { name, label, value, onChange, monthyearformat = 0 } = props;

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      <KeyboardDatePicker
        // disableToolbar
        variant="inline"
        inputVariant="outlined"
        label={label}
        format={monthyearformat === 0 ? 'dd/MM/yyyy' : 'MM/yyyy'}
        fullWidth
        size="small"
        name={name}
        value={value}
        onChange={date => onChange(convertToDefEventPara(name, date))}
        autoOk
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
