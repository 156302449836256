'use-strict';
import { Grid, Paper, Toolbar } from '@material-ui/core';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import Delete from '@material-ui/icons/Delete';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import SharedImg from '../../assets/images/009-expenses.png';
import ConfirmDialog from '../../components/ConfirmDialog';
import Controls from '../../components/controls/Controls';
import NotFounded from '../../components/molecules/NotFounded';
import Notification from '../../components/Notification';
import PageHeader from '../../components/PageHeader';
import { Context } from '../../Context/AuthContext';
import { consulAlltReports, deleteReport } from '../../services/reportService';
import {
  formatToDate,
  formatToReportPeriod,
  replaceBuildingName,
} from '../../utils/converter/formatter';

function ReportListPage() {
  const { rangePeriod } = useContext(Context);
  const [reportList, setReportList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const consultReportsByPeriod = useCallback(async period => {
    try {
      setLoading(true);
      const result = await consulAlltReports(period);
      setReportList(result.data);
    } catch (error) {
      setNotify({
        isOpen: true,
        message:
          'Não foi possível consultar os registros, tente novamente mais tarde.',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    consultReportsByPeriod({ period: rangePeriod.reportPeriod });
  }, [rangePeriod.reportPeriod, consultReportsByPeriod]);

  const onDelete = id => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteReport(id);
    setReportList(reportList.filter(item => item._id !== id));
    setNotify({
      isOpen: true,
      message: 'Deletado com sucesso!',
      type: 'error',
    });
  };

  const columns = [
    {
      selector: row =>
        `${formatToDate(row.consultRange.startDate)} - ${formatToDate(
          row.consultRange.endDate
        )}`,
      name: 'Período',
      flex: 1,
    },
    {
      selector: row => row.announcement.internalName,
      name: 'Unidade',
      flex: 1,
    },
    {
      selector: row => replaceBuildingName(row.announcement.building) || '',
      name: 'Prédio',
      flex: 1,
    },
    {
      selector: row => row.contract,
      name: 'Modelo de Contrato',
      flex: 1,
    },
    {
      selector: row => row.condition,
      name: 'Modelo de Cálculo',
      flex: 1,
    },
    {
      name: 'Ações',
      sortable: false,
      cell: row => {
        return (
          <Controls.ActionButton
            color="secondary"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: 'Você tem certeza que quer deletar esse registro?',
                subTitle: 'Você não pode desfazer essa alteração',
                onConfirm: () => {
                  onDelete(row._id);
                },
              });
            }}
          >
            <Delete />
          </Controls.ActionButton>
        );
      },
    },
  ];

  const [filteredSearch, setFilteredSearch] = useState('');

  const reportFiltered = reportList.filter(
    o =>
      o.announcement.internalName.toLowerCase().includes(filteredSearch) ||
      filteredSearch === ''
  );

  const [selectedPeriod, setSelectedPeriod] = useState(new Date());

  const handlePeriod = newPeriod => {
    const period = newPeriod;
    setSelectedPeriod(period);
    consultReportsByPeriod({ period: formatToReportPeriod(period) });
  };

  return (
    <>
      <PageHeader
        title="Relatórios"
        subTitle="Lista relatórios"
        icon={<img alt="iconMenu" src={SharedImg} height={'35px'} />}
      />
      <Paper>
        <Toolbar>
          <Grid container>
            <Grid item xs={4}>
              {/* {selectedPeriod} */}
              <Controls.DatePicker
                name="period"
                label="Periodo"
                value={selectedPeriod}
                onChange={handlePeriod}
                views={['month']}
                monthyearformat={1}
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <Controls.Input
                placeholder="Buscar por apartamento"
                name="announcement"
                value={filteredSearch}
                onChange={e => {
                  setFilteredSearch(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <DataTable
          columns={columns}
          data={reportFiltered}
          pagination
          progressPending={loading}
          noDataComponent={
            <NotFounded
              title="Não foram encontrados registros com os critérios de busca
              especificados."
              icon={<BusinessRoundedIcon fontSize="large" />}
            />
          }
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default ReportListPage;
