import { Card, CardContent, makeStyles } from '@material-ui/core';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { schemeColors3 } from '../../utils/constants/colors';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0 0 11px rgba(33,33,33,.2)',
    },
    // height: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  indicatorText: {
    fontWeight: 'bold',
  },
});

function DoughnutChart({ labels = [], data = [], customTitle }) {
  const classes = useStyles();
  const defaultOptions = {
    responsive: true,
    animation: false,
    plugins: {
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: { fontSize: 12, boxWidth: 6, usePointStyle: true },
      },
      title: {
        display: true,
        text: customTitle,
      },
      datalabels: {
        formatter: (value) => {
          return value + '%';
        },
        color: 'white',
        display: true,
        borderRadius: 3,
      },
    },
  };

  const infoData = {
    labels,
    datasets: [
      {
        label: '# of Votes',
        data,
        backgroundColor: schemeColors3,
        borderColor: schemeColors3,
        borderWidth: 1,
      },
    ],
  };
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div style={{ width: '285px', margin: '0 auto', height: 'auto' }}>
          <Doughnut
            data={infoData}
            plugins={[ChartDataLabels]}
            options={defaultOptions}
          />
        </div>
      </CardContent>
    </Card>
  );
}

export default DoughnutChart;
