import { Grid, Paper, Toolbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import PageHeader from '../../components/PageHeader';
import SharedImg from '../../assets/images/009-expenses.png';
import {
  getAllSharedDivision,
  insertSharedDivision,
  updateSharedDivision,
} from '../../services/sharedDivisionService';
import { formatToDate } from '../../utils/converter/formatter';
import Popup from '../../components/Popup';
import SharedForm from './SharedForm';
import Notification from '../../components/Notification';
import ConfirmDialog from '../../components/ConfirmDialog';
import Controls from '../../components/controls/Controls';
import { Edit } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';

function SharedPage() {
  const [sharedList, setSharedList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const initialLoad = async (start = '', end = '') => {
    try {
      setLoading(true);
      const result = await getAllSharedDivision(start, end);
      setSharedList(result.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    initialLoad();
  }, []);

  const addOrEdit = async (sharedDivision, resetForm) => {
    const payload = {
      ...sharedDivision,
    };
    if (payload._id) {
      await updateSharedDivision(payload._id, payload);
      setNotify({
        isOpen: true,
        message: 'Atualizado com sucesso!',
        type: 'success',
      });
    } else {
      await insertSharedDivision(payload);
      setNotify({
        isOpen: true,
        message: 'Inserido com sucesso!',
        type: 'success',
      });
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    initialLoad();
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const columns = [
    {
      selector: (row) => formatToDate(row.launchDate),
      name: 'Data referência',
      flex: 1,
      sortable: true,
    },
    {
      selector: (row) => row.quantity,
      name: 'Quantidade',
      flex: 1,
    },
    {
      name: 'Ações',
      sortable: false,
      cell: (row) => {
        return (
          <Controls.ActionButton
            color="primary"
            onClick={() => {
              openInPopup(row);
            }}
          >
            <Edit />
          </Controls.ActionButton>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader
        title="Divisão do Mês"
        subTitle="Lista de divisões do mês"
        icon={<img alt="iconMenu" src={SharedImg} height={'35px'} />}
      />
      <Paper>
        <Toolbar>
          <Grid container>
            <Grid item xs>
              <Controls.Button
                size="medium"
                onClick={() => {
                  setOpenPopup(true);
                  setRecordForEdit(null);
                }}
                startIcon={<AddIcon />}
                variant="outlined"
                text="Inserir"
              />
            </Grid>
          </Grid>
        </Toolbar>
        <DataTable
          columns={columns}
          data={sharedList}
          pagination
          progressPending={loading}
        />
      </Paper>
      <Popup
        title="Divisão do Mês"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <SharedForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default SharedPage;
