import { Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import PageHeader from '../../components/PageHeader';
import { getAllExpensesModel } from '../../services/expenseService';
import ExpenseModelImg from '../../assets/images/008-income.png';

function ExpenseModelPage() {
  const [expensesModel, setExpensesModel] = useState([]);
  const [loading, setLoading] = useState(false);

  const initialLoad = async () => {
    try {
      setLoading(true);
      const result = await getAllExpensesModel();
      setExpensesModel(result.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    initialLoad();
  }, []);

  const columns = [
    {
      selector: (row) => row.modelName,
      name: 'Nome do modelo',
      flex: 1,
    },
  ];

  return (
    <>
      <PageHeader
        title="Modelos de Despesa"
        subTitle="Lista de modelos de despesa"
        icon={<img alt="iconMenu" src={ExpenseModelImg} height={'35px'} />}
      />
      <Paper>
        <DataTable
          columns={columns}
          data={expensesModel}
          pagination
          progressPending={loading}
        />
      </Paper>
      {/* <Popup title="Prédio" openPopup={openPopup} setOpenPopup={setOpenPopup}>
            <BuildingForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
          </Popup>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          /> */}
    </>
  );
}

export default ExpenseModelPage;
