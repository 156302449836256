import { Grid } from '@material-ui/core';
import { Form, Formik, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Controls from '../../components/controls/Controls';
import { getAllAnnouncements } from '../../services/announcementService';
import { mapToSelectField2 } from '../../utils/converter/mapToSelect';

const schema = Yup.object().shape({
  announcements: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
  ),
  name: Yup.string().required('Campo obrigatório'),
  description: Yup.string(),
});

const statusItems = [
  { id: 'active', title: 'Ativo' },
  { id: 'inactive', title: 'Desativado' },
  { id: 'suspended', title: 'Suspenso' },
];

export default function BuildingForm({ addOrEdit, recordForEdit }) {
  const initial = {
    announcements: [],
    name: '',
    description: '',
    coverImage: '',
    status: '',
    staysId: '',
    address: {},
    geo: {},
  };
  const [initialValues, setInitalValues] = useState(initial);
  const [submitButtonText, setSubmitButtonText] = useState('Inserir');
  const [buildingsAnnoucements, setBuildingsAnnoucements] = useState([]);

  async function onSubmit(values, { resetForm }) {
    addOrEdit(values, resetForm);
  }

  const initialLoad = async () => {
    const [announcement] = await Promise.all([getAllAnnouncements()]);
    if (announcement.success) {
      setBuildingsAnnoucements(
        mapToSelectField2(announcement.data, 'internalName')
      );
    }
  };

  useEffect(() => {
    initialLoad();
  }, []);

  useEffect(() => {
    if (recordForEdit != null) {
      setSubmitButtonText('Atualizar');
      setInitalValues(() => ({
        ...recordForEdit,
        announcements:
          recordForEdit.announcements.length >= 1
            ? mapToSelectField2(recordForEdit.announcements, 'internalName')
            : [],
      }));
    }
  }, [recordForEdit, submitButtonText]);

  return (
    <Formik
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <Controls.Input
                name="name"
                label="Nome"
                value={values.name}
                onChange={handleChange}
                error={errors.name && touched.name}
              />
            </Grid>

            <Grid item xs={6}>
              <Controls.RadioGroup
                name="status"
                label="Status"
                value={values.status}
                onChange={handleChange}
                items={statusItems}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="description"
                label="Descrição"
                value={values.description}
                onChange={handleChange}
                error={errors.description && touched.description}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                className="custom-select"
                name="announcements"
                options={buildingsAnnoucements}
                component={Controls.SelectCustom}
                placeholder="Anúncios desse prédio..."
                isMulti={true}
              />
            </Grid>
          </Grid>
          <div>
            <Controls.Button
              type="submit"
              disabled={isSubmitting}
              text={submitButtonText}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
