import Expenses from './Expenses';
import Buildings from './Buildings';
import MainPage from './MainPage';
import AnnouncementsPage from './Announcements';
import BookingsPage from './Bookings';
import ExpenseModelPage from './ExpenseModel';
import OwnersPage from './Owners';
import SharedPage from './Shared';
import Login from './Login';
import ReportUnitPage from './ReportUnit';
import ReportListPage from './ReportList';

const Pages = {
  Expenses,
  Buildings,
  MainPage,
  ReportUnitPage,
  ReportListPage,
  AnnouncementsPage,
  BookingsPage,
  ExpenseModelPage,
  OwnersPage,
  SharedPage,
  Login,
};

export default Pages;
