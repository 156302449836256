import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import Controls from '../../components/controls/Controls';
import { getAllAnnouncements } from '../../services/announcementService';
import { mapToSelectField2 } from '../../utils/converter/mapToSelect';

export default function OwnerForm({ addOrEdit, recordForEdit }) {
  const initial = {
    fullName: '',
    email: '',
    birthday: '',
    phoneNumber: 0,
    extraInfo: '',
    announcements: [],
  };
  const [initialValues, setInitalValues] = useState(initial);
  const [submitButtonText, setSubmitButtonText] = useState('Inserir');
  const [buildingsAnnoucements, setBuildingsAnnoucements] = useState([]);

  async function onSubmit(values, { resetForm }) {
    addOrEdit(values, resetForm);
  }

  const initialLoad = async () => {
    const [announcement] = await Promise.all([getAllAnnouncements()]);
    if (announcement.success) {
      setBuildingsAnnoucements(
        mapToSelectField2(announcement.data, 'internalName')
      );
    }
  };

  useEffect(() => {
    initialLoad();
  }, []);

  useEffect(() => {
    if (recordForEdit != null) {
      setSubmitButtonText('Atualizar proprietário');
      setInitalValues(() => ({
        ...recordForEdit,
        announcements:
          recordForEdit.announcements.length >= 1
            ? mapToSelectField2(recordForEdit.announcements, 'internalName')
            : [],
      }));
    }
  }, [recordForEdit, submitButtonText]);

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <Controls.Input
                name="fullName"
                label="Nome completo"
                value={values.fullName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Input
                name="birthday"
                label="Aniversário"
                value={values.birthday}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Input
                name="phoneNumber"
                label="Telefone"
                value={values.phoneNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                className="custom-select"
                name="announcements"
                options={buildingsAnnoucements}
                component={Controls.SelectCustom}
                placeholder="Anúncios desse proprietário..."
                isMulti={true}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Controls.Button
                type="submit"
                disabled={isSubmitting}
                text={submitButtonText}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
