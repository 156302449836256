import { Box, CircularProgress, Grid, Paper } from '@material-ui/core';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import React, { useContext, useState } from 'react';
import Notification from '../../components/Notification';
import PageHeader from '../../components/PageHeader';
import InfoPageCard from '../../components/molecules/InfoPageCard';
import {
  formatToReportPeriod,
  toMonetaryValue,
} from '../../utils/converter/formatter';

import { Context } from '../../Context/AuthContext';

import { useCallback, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DashboardImg from '../../assets/images/001-business-report.png';
import NotFounded from '../../components/molecules/NotFounded';
import { consulAlltReports } from '../../services/reportService';
import { FilterForm } from './filterForm';

import csvDownload from 'json-to-csv-export';
import Controls from '../../components/controls/Controls';

const transFormConditionItems = {
  valorBruto: 'Bruto',
  resultadoLiquido: 'Resultado Líquido',
  alugado: 'Alugado',
};

const transformContractType = {
  hibrid: 'Híbrido',
  rent: 'Aluguel',
  partner: 'Parceria',
};

export default function MainPage() {
  const { rangePeriod } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [filterParams, setFilterParams] = useState({ contract: '' });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [reportList, setReportList] = useState([]);

  const consultReportsByPeriod = useCallback(async search => {
    try {
      setLoading(true);
      const result = await consulAlltReports(search);
      setReportList(result.data);
      // filledWithData(result.data);
    } catch (error) {
      setNotify({
        isOpen: true,
        message:
          'Não foi possível consultar os registros, tente novamente mais tarde.',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    consultReportsByPeriod({ period: rangePeriod.reportPeriod });
  }, [rangePeriod.reportPeriod, consultReportsByPeriod]);

  const handleSearch = searchValues => {
    const { period } = searchValues;
    setFilterParams(searchValues);
    const payloadToRequest = {
      ...searchValues,
      period: formatToReportPeriod(period),
    };
    consultReportsByPeriod(payloadToRequest);
  };

  const columns = [
    {
      selector: row =>
        `${row.announcement.internalName} (${row.announcement.building})`,
      name: 'Unidade',
      flex: 1,
    },
    {
      selector: row =>
        `${transFormConditionItems[row.condition]} - ${
          transformContractType[row.contract]
        }`,
      name: 'Modelo',
      flex: 1,
    },
    {
      selector: row => toMonetaryValue(row.indicators.bruteSum, true),
      name: 'Faturamento Bruto',
      flex: 1,
    },
    {
      selector: row => toMonetaryValue(row.indicators.chargesSum, true),
      name: 'Encargos',
      flex: 1,
    },
    {
      selector: row => toMonetaryValue(row.indicators.liquidSum, true),
      name: 'Faturamento Líquido',
      flex: 1,
    },
    {
      selector: row =>
        toMonetaryValue(row.indicators.expenses.debitExpense, true),
      name: 'Despesas',
      flex: 1,
    },
    {
      selector: row =>
        toMonetaryValue(row.indicators.expenses.resultOfOperation, true),
      name: 'Resultado',
      flex: 1,
    },
    ...(filterParams.contract === 'partner' ||
    filterParams.contract === 'hibrid'
      ? [
          {
            selector: row =>
              toMonetaryValue(row.indicators.expenses.refundExpense, true),
            name: 'Reembolso',
            flex: 1,
          },
          {
            selector: row =>
              toMonetaryValue(row.indicators.expenses.hyValue, true),
            name: 'Taxa',
            flex: 1,
          },
          {
            selector: row =>
              toMonetaryValue(row.indicators.expenses.distribution, true),
            name: 'Distribuição',
            flex: 1,
          },
        ]
      : []),
  ];

  const mappedReport = reportList?.map(report => ({
    unidade: report.announcement.internalName,
    predio: report.announcement.building,
    modeloCondicao: transFormConditionItems[report.condition],
    modeloContrato: transformContractType[report.contract],
    bruto: toMonetaryValue(report.indicators.bruteSum),
    encargos: toMonetaryValue(report.indicators.chargesSum),
    liquido: toMonetaryValue(report.indicators.liquidSum),
    despesas: toMonetaryValue(report.indicators.expenses.debitExpense),
    resultado: toMonetaryValue(report.indicators.expenses.resultOfOperation),
    reembolso: toMonetaryValue(report.indicators.expenses.refundExpense),
    taxa: toMonetaryValue(report.indicators.expenses.hyValue),
    distribuicao: toMonetaryValue(report.indicators.expenses.distribution),
  }));

  const dataToConvert = {
    data: mappedReport,
    filename: `Relatorio CSV - ${filterParams.period}`,
    delimiter: ',',
    headers: [
      'Unidade',
      'Predio',
      'Condição',
      'Contrato',
      'Resultado Bruto',
      'Encargos',
      'Resultado Líquido',
      'Despesas',
      'Resultado/Lucro',
      'Reembolso',
      'Taxa',
      'Distribuição',
    ],
  };

  return (
    <>
      <PageHeader
        title="PAINEL DE CONTROLE"
        subTitle={`Indicadores de Desempenho HY`}
        icon={<img alt="iconMenu" src={DashboardImg} height={'35px'} />}
      />
      <Box>
        <FilterForm
          currentPeriod={rangePeriod.startDate}
          handleSearch={handleSearch}
        />
        <Grid container></Grid>
        <Paper>
          <DataTable
            columns={columns}
            data={reportList}
            pagination
            progressPending={loading}
            actions={
              <Controls.Button
                text="Exportar CSV"
                color="primary"
                onClick={() => csvDownload(dataToConvert)}
              />
            }
            progressComponent={
              <InfoPageCard
                title="Carregando..."
                icon={<CircularProgress size={60} />}
              />
            }
            noDataComponent={
              <NotFounded
                title="Não foram encontrados registros com os critérios de busca
              especificados."
                icon={<BusinessRoundedIcon fontSize="large" />}
              />
            }
          />
        </Paper>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
