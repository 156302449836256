import { TextField } from '@material-ui/core';
import React from 'react';

export default function Input(props) {
  const {
    name,
    label,
    value,
    error = null,
    variant,
    onChange,
    ...other
  } = props;
  return (
    <TextField
      variant={variant || 'outlined'}
      label={label}
      name={name}
      value={value}
      fullWidth
      size="small"
      onChange={onChange}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
}
