import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Pages from '../Pages';
import PrivateRoute from './PrivateRoute';

export default function Routes() {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={Pages.MainPage} />
      <PrivateRoute exact path="/report" component={Pages.ReportUnitPage} />
      <PrivateRoute exact path="/report-list" component={Pages.ReportListPage} />
      <PrivateRoute exact path="/despesas" component={Pages.Expenses} />
      <PrivateRoute exact path="/predios" component={Pages.Buildings} />
      <PrivateRoute exact path="/reservas" component={Pages.BookingsPage} />
      <PrivateRoute
        exact
        path="/apartamentos"
        component={Pages.AnnouncementsPage}
      />
      <PrivateRoute exact path="/proprietarios" component={Pages.OwnersPage} />
      <PrivateRoute exact path="/divisao" component={Pages.SharedPage} />
      <PrivateRoute
        exact
        path="/modelo-despesa"
        component={Pages.ExpenseModelPage}
      />
      <Route exact path="/login" component={Pages.Login} />
    </Switch>
  );
}
