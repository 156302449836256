import {
  Avatar,
  Box,
  Container,
  CssBaseline,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import Controls from '../../components/controls/Controls';
import AssessmentIcon from '@material-ui/icons/Assessment';

import { Context } from '../../Context/AuthContext';
import LoadingProgress from '../../components/molecules/LoadingProgress';
import { useStyles } from '../../styles/main';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  username: Yup.string('Insira seu login').required('Usuário é obrigatório'),
  password: Yup.string('Insira sua senha').required('Senha é obrigatória'),
});

function Login() {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema: validationSchema,
    onSubmit: (values) => handleLogin(values),
  });

  const { authenticated, handleLogin, loading } = useContext(Context);

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <div style={{ display: 'flex', backgroundColor: '#1a1a74' }}>
      <CssBaseline />
      <main
        style={{
          flexGrow: 1,
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        {authenticated ? (
          <Redirect to="/" />
        ) : (
          <Container maxWidth="xs">
            <Box
              component={Paper}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 40,
              }}
            >
              <Avatar className={classes.blueHy}>
                <AssessmentIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                HY Control - Relatórios
              </Typography>
              <Box
                component="form"
                onSubmit={formik.handleSubmit}
                noValidate
                sx={{
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '12px',
                }}
              >
                <TextField
                  fullWidth
                  id="username"
                  name="username"
                  label="Login"
                  variant="outlined"
                  size="small"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                />
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Senha"
                  variant="outlined"
                  size="small"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />

                <Controls.Button type="submit" text={'Entrar'} fullWidth />
              </Box>
            </Box>
          </Container>
        )}
      </main>
    </div>
  );
}

export default Login;
