import { callToBackend } from '../utils/api/genericCallToApi';

export const getAllBuildings = async () => {
  return await callToBackend('buildings', 'GET');
};

export const insertBuilding = async (building) => {
  const payload = JSON.stringify(building);
  await callToBackend('buildings', 'POST', payload);
};

export const updateBuilding = async (id, building) => {
  const payload = JSON.stringify(building);
  await callToBackend(`buildings/${id}`, 'PATCH', payload);
};

export const deleteBuilding = async (id) => {
  await callToBackend(`buildings/${id}`, 'DELETE');
};
