import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import Controls from '../../components/controls/Controls';

const conditionItems = [
  { id: 'valorBruto', title: 'Bruto' },
  { id: 'resultadoLiquido', title: 'Resultado Líquido' },
  { id: 'alugado', title: 'Alugado' },
];

const statusItems = [
  { id: 'active', title: 'Ativo' },
  { id: 'inactive', title: 'Desativado' },
  { id: 'suspended', title: 'Suspenso' },
];

const contractType = [
  { id: 'hibrid', title: 'Híbrido' },
  { id: 'rent', title: 'Aluguel' },
  { id: 'partner', title: 'Parceria' },
];

export default function AnnouncementInsertForm({ addOrEdit }) {
  const initial = {
    internalName: '',
    staysId: '',
    billing: { condition: '', value: 0 },
    contract: { type: '' },
    status: '',
  };

  async function onSubmit(values, { resetForm }) {
    addOrEdit(values, resetForm);
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initial}>
      {({ handleSubmit, handleChange, values, isSubmitting }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                label="Nome interno"
                fullWidth
                name="internalName"
                value={values.internalName}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                label="Id Stays"
                fullWidth
                name="staysId"
                value={values.staysId}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={4}>
              <Controls.Select
                name="status"
                label="Status"
                value={values.status}
                onChange={handleChange}
                options={statusItems}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Select
                name="billing.condition"
                label="Condição de Cálculo"
                value={values?.billing?.condition}
                onChange={handleChange}
                options={conditionItems}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                label="Valor da Taxa"
                name="billing.value"
                value={values?.billing?.value}
                fullWidth
                size="small"
                type="number"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Select
                name="contract.type"
                label="Tipo"
                value={values?.contract?.type}
                onChange={handleChange}
                options={contractType}
              />
            </Grid>
          </Grid>
          <div>
            <Controls.Button
              type="submit"
              disabled={isSubmitting}
              text={'Inserir'}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
