import React from 'react';
import { Typography } from '@material-ui/core';

function LabelViewText({ title, value }) {
  return value ? (
    <Typography variant="body1" component="p">
      <strong>{title}: </strong> {value}
    </Typography>
  ) : null;
}

export default LabelViewText;
