import { callToBackend } from '../utils/api/genericCallToApi';

export const getAllExpenses = async (startDate, endDate) => {
  return await callToBackend('expenses/filter', 'GET', null, {
    startDate,
    endDate,
  });
};

export const insertExpense = async (expense) => {
  const payload = JSON.stringify(expense);
  await callToBackend('expenses', 'POST', payload);
};

export const updateExpense = async (id, expense) => {
  const payload = JSON.stringify(expense);
  await callToBackend(`expenses/${id}`, 'PATCH', payload);
};

export const deleteExpense = async (id) => {
  await callToBackend(`expenses/${id}`, 'DELETE');
};

export const getAllExpensesModel = async () => {
  return await callToBackend('expenses/model', 'GET');
};
