import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  item: {
    minWidth: 275,
    height: 260,
    marginTop: 150,
    textAlign: 'center',
  },
});

function LoadingProgress() {
  const classes = useStyles();
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item className={classes.item}>
        <CircularProgress size={60} />
        <Typography className={classes.title} gutterBottom>
          Carregando...
        </Typography>
      </Grid>
    </Grid>
  );
}

export default LoadingProgress;
