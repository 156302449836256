import { useCallback, useEffect, useState } from 'react';
import { endOfMonth, startOfMonth } from 'date-fns';
import { formatToDate, formatToReportPeriod } from '../utils/converter/formatter';

export default function useHandleResources() {
  const [period, setPeriod] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection',
    },
  ]);

  const [rangePeriod, setRangePeriod] = useState('');
  const [aptoUnit, setAptoUnit] = useState({});

  useEffect(() => {
    setRangePeriod({
      startDate: period[0].startDate,
      endDate: period[0].endDate,
      formattedStartDate: formatToDate(period[0].startDate),
      formattedEndDate: formatToDate(period[0].endDate),
      reportPeriod: `${formatToReportPeriod(period[0].startDate)}`,
      key: 'selection',
    });
  }, [period]);

  const handleRangePeriod = useCallback((ranges) => {
    const { selection } = ranges;
    setPeriod([selection]);
  }, []);

  const handleAptoUnit = useCallback((unit) => {
    setAptoUnit(unit);
  }, []);

  return { handleRangePeriod, rangePeriod, handleAptoUnit, aptoUnit };
}
