import { callToBackend } from '../utils/api/genericCallToApi';

export const getAllSharedDivision = async (startDate, endDate) => {
  return await callToBackend('shared', 'GET', null, {
    startDate,
    endDate,
  });
};

export const insertSharedDivision = async (sharedDivision) => {
  const payload = JSON.stringify(sharedDivision);
  await callToBackend('shared', 'POST', payload);
};

export const updateSharedDivision = async (id, sharedDivision) => {
  const payload = JSON.stringify(sharedDivision);
  await callToBackend(`shared/${id}`, 'PATCH', payload);
};

// export const deleteBuilding = async (id) => {
//   await callToBackend(`buildings/${id}`, 'DELETE');
// };
