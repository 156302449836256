import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { userLogin } from '../services/loginService';
import useLocalStorage from './useLocalStorage';

export default function useAuth() {
  const [storageUser, setStorageUser, removeStorageUser] =
    useLocalStorage('@authApp: user');
  const [storageToken, setStorageToken, removeStorageToken] =
    useLocalStorage('@authApp: token');

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (storageUser && storageToken) {
      setUser(storageUser);
      axios.defaults.headers.Authorization = `Bearer ${storageToken}`;
    }
    setLoading(false);
  }, [storageUser, storageToken]);

  const handleLogin = async (credentials) => {
    try {
      setLoading(true);
      const { success, data } = await userLogin(credentials);
      if (success) {
        setUser(data.user);
        axios.defaults.headers.Authorization = `Bearer ${data.access_token}`;
        setStorageUser(data.user);
        setStorageToken(data.access_token);
      }
    } catch (error) {
      throw new Error('falha na autenticação');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = useCallback(() => {
    setLoading(true);
    axios.defaults.headers.Authorization = undefined;
    removeStorageUser();
    removeStorageToken();
    setUser({});
    setLoading(false);
  }, [removeStorageUser, removeStorageToken]);

  return {
    loading,
    authenticated: user && user.name ? true : false,
    user,
    handleLogin,
    handleLogout,
  };
}
