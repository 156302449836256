import Input from './Input';
import Select from './Select';
import DatePicker from './DatePicker';
import Checkbox from './CheckBox';
import RadioGroup from './RadioGroup';
import Button from './Button';
import ActionButton from './ActionButton';
import AutoComplete from './AutoComplete';
import NumberFormatCustom from './NumberFormatCustom';
import DateRangeCustom from './DateRangeCustom';
import SelectCustom from './SelectCustom';

const Controls = {
  Input,
  Select,
  Checkbox,
  DatePicker,
  RadioGroup,
  Button,
  ActionButton,
  AutoComplete,
  NumberFormatCustom,
  DateRangeCustom,
  SelectCustom,
};

export default Controls;
