import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

export default function AutoComplete(props) {
  const { name, label, error = null, onChange, options, style } = props;

  return (
    <FormControl {...(error && { error: true })}>
      <Autocomplete
        options={options}
        onChange={onChange}
        style={style}
        fullWidth
        getOptionLabel={(option) => (option.title ? option.title : '')}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            label={label}
            size="small"
            variant="outlined"
            fullWidth
          />
        )}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
