import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import Layout from '../components/Layout';
import LoadingProgress from '../components/molecules/LoadingProgress';
import { Context } from '../Context/AuthContext';

export default function PrivateRoute({ component: Component, ...props }) {
  const { loading, authenticated } = useContext(Context);

  if (loading) {
    return <LoadingProgress />;
  }
  return (
    <Route
      {...props}
      render={() =>
        authenticated ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}
