import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

export default function SelectCustom({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
}) {
  const animatedComponents = makeAnimated();

  const onChange = (option) => {
    form.setFieldValue(
      field.name,
      isMulti
        ? option.map((item) => ({ label: item.label, value: item.value }))
        : { value: option.value, label: option.label }
    );
  };

  return (
    <Select
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      closeMenuOnSelect={false}
      components={animatedComponents}
      className={className}
      name={field.name}
      value={field.value}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
    />
  );
}
