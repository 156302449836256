import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { formatToDate, toMonetaryValue } from '../../utils/converter/formatter';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#dddddd',
  },
});

function ExpandedComponent({ data, fieldsToShow }) {
  const classes = useStyles();

  const listOfFields = fieldsToShow;

  const objValues = Object.entries(data);
  const campos = objValues
    .map(([key, value]) => ({ title: key, description: value }))
    .filter(filteredFields(listOfFields))
    .map(parseFieldsToDate)
    .map(checkEncargos);

  return (
    <Box component="div" p={2} className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        spacing={2}
      >
        {_.flatten(campos).map((item, index) => (
          <Grid item xs={3} key={index}>
            <Typography variant="body2">
              <strong>{item.title}</strong>: {item.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function parseFieldsToDate(obj) {
  if (obj.title === 'Chegada' || obj.title === 'Saida') {
    return { ...obj, description: formatToDate(obj.description) };
  }
  return obj;
}

function filteredFields(listOfFields) {
  return (item) => listOfFields.includes(item.title);
}

function checkEncargos(field) {
  if (field.title === 'EncargosVenda') {
    return field.description.map((ab) => ({
      title: ab.desc,
      description: toMonetaryValue(ab.val, true),
    }));
  }
  return field;
}

export default ExpandedComponent;
